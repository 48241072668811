<template lang="pug">
  div(
    
    ref="editable"
    contenteditable
    v-on="listeners"
    style="min-height: 35px; outline-color: transparent;"
  ).py-2.px-1.mt-1
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput };
    },
  },
  watch: {
    "value": function() {
      if(this.value == "") {
         this.$refs.editable.innerText = this.value;
      }
      
    }
   
  },
  mounted() {
    this.$refs.editable.innerText = this.value;
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.innerText);
    },
  },
};
</script>