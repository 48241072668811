<template lang="pug">
  .onboard-modal
    b-modal(id="onboardmodal", :title="title", size="xl", ok-variant="outline-primary", cancel-variant="link", ref="onboardmodal",  @ok="save()")

    
       
        b-form(size="sm", autocomplete="off", v-if="step==1", style="min-height: 500px")
          
          .form-row(v-if="loading").p-2
            .col-12.text-center
              b-spinner().spinner-border.text-primary.m-auto
            .col-12
              .mt-5.text-center
                .text-primary.m-auto Setting up your account
          .form-row(v-else).p-2
            .col-2
            .col-4
              b-img(src="/isearch.png" fluid).mt-5
            .col-1
            .col-5
              p.mt-5 
                b We are happy to have you onboard!
    
              p Manage and expand your Corporate's startup, tech and innovation ecosystems. 
              p Scout for startups, partners and investment targets and receive information about foundings, fundings and other market developments.

              p.mt-5 In the next step, we will help you to set up your feed.
        b-form(size="sm", autocomplete="off", v-if="step==2", style="min-height: 500px")

          .form-row()
            .col-12
              .row.mt-2()
                .col-12
                  label Select a category

              b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="6", v-if="industries").form-row
                b-col(v-for="topic in industries.rows")
                  .topic-badge( v-bind:class="{'active': (selectedIndustry && selectedIndustry._id == topic._id)}", @click="getTopics(topic)", style="cursor: pointer").mt-2.align-middle 
                    .inside {{topic.name}}

          .row.mt-3(v-if="topics && topics.length > 0", style="max-height: 400px; overflow-y: scroll")
            .col-12
              label.mt-4 Topics
              .text-muted Click on a topic to subscribe/unsubscribe.
              b-overlay(:show="$store.state.loading").w-100
                b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="5").mt-2.form-row
                
                  div(v-if="topics && topics.length == 0").text-muted.mt-3 There are no topics in this category.
                  
                  b-col(v-for="topic in $_.orderBy(topics, ['name'], ['asc'])").d-flex.align-items-stretch.mb-2.w-100
                        
                          b-card(no-body, v-bind:class="{'active': $store.state.user.pins.map(x => x.item_id).indexOf(topic._id) > -1}", style="min-height: 4rem").w-100.h-100.shadow-none.t
                          
                                  h-pin(:item_id="topic._id", item_type="Topic", variant="none").h-100.w-100
                                     | {{topic.name}}
                                     template(#unfollow)
                                      | {{topic.name}}
                                      .small Unfollow
                                     template(slot="following")
                                      | {{topic.name}}
                                      .small Following

                    

        b-form(size="sm", autocomplete="off", v-if="step==3", style="min-height: 500px")
          
          b-row
            .col-4
              b-img(src="/happy.png" fluid).mt-5
            .col-8
              .text-muted.my-2 What can you do next?
              b-card.mb-3.shadow-none
                .form-row()
                  .col-2.text-center
                    
                    .i.fas.fa-building(style="font-size: 2em; color: #00bcd4;")
                    div.mt-1 Companies
                  .col-10.my-auto(style="max-width: 600px") Find the most relevant companies in our database and enrich their profiles with your own insights.

              b-card.mb-3.shadow-none
                .form-row()
                  .col-2.text-center
                    
                    .i.fas.fa-business-time(style="font-size: 2em; color: #00bcd4;")
                    div.mt-1 Projects
                  .col-10.my-auto(style="max-width: 600px") Create your first project and organize your selection of companies in a funnel adapted to your workflow. 
              
              b-card.mb-3.shadow-none
                .form-row()
                  .col-2.text-center
                    
                    .i.fas.fa-lightbulb(style="font-size: 2em; color: #00bcd4;")
                    div.mt-1 Topics
                  .col-10.my-auto(style="max-width: 600px") Receive market insights on fundings &amp; investments, foundings and media attention. 

              b-card.mb-3.shadow-none
                .form-row()
                  .col-2.text-center
                    
                    .i.fas.fa-newspaper(style="font-size: 2em; color: #00bcd4;")
                    div.mt-1 News
                  .col-10.my-auto(style="max-width: 600px") Receive an update on all changes in your relevant markets. 
                  
        template(slot="modal-footer")
              div.mr-auto
                
                b-btn(variant="outline-secondary" @click="saveOnboarded(); $refs.onboardmodal.hide(false)") Skip
              b-btn(variant="secondary"  @click="step--", v-if="step > 1")#user-modal-save Back
              b-btn(variant="primary"  @click="step++", , v-if="step < 3")#user-modal-save Next
              b-btn(variant="primary"  @click="saveOnboarded(); $refs.onboardmodal.hide(false)", , v-if="step == 3")#user-modal-save Get started!



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'OnboardModal',
  props: [],
 
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        loading: true,
        uid: null,
        title: "Welcome to the Ecosystem Manager",
        form: {"name": "", "permissions": [], "active": true, "send_confirmation": true},
        tole: {},
        industries: [],
        trend: {},
        step: 1,
        topics: [],
        options: [],
        selectedIndustry: null,
        submitStatus: null,
        ok_btn_text: "Add Role"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.getIndustries();
  },

  methods: {

    saveOnboarded() {
      this.$auth.user.is_onboarded = true;
      this.$auth.saveUserData(this.$auth.user).then(() => {

                this.$auth.getUserData().then((resp) => {
                  this.$store.commit("LOAD_USER", resp.user);
                });

                this.$store.dispatch("stop_loading");

      });
    },

     getTrends(topic_id) {
        this.$store.dispatch("start_loading");
         Vue.http.get(Config.config.base_host+'/topics_v1/media_attention/'+topic_id).then(resp => {
            this.trends[topic_id] = resp.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        }); 
      },
      getIndustries() {
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', {"topic_type": "industry"}).then(resp => {
         
          this.industries = resp.body;
          this.industries.rows = this.$_.sortBy(this.industries.rows, ["name"])

          this.getTopics(this.industries.rows[0]); 
          
        }); 

      },

      getTopics(industry) {
        this.selectedIndustry = industry;
        
        let params = {};
        
        if(this.project) {
          params = {"topic_type": "topic", "project_id": [this.project._id]};
        } else {
          params =  {"topic_type": "topic", "parent_id": [industry._id]};
        
        }

        params['limit'] = 100;
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
          this.$store.state.loading = false;
        }); 
      },

       getFollowTopic() {
      
        this.selectedIndustry = null;
       
        let params = {"topic_type": "topic", "bookmarked": true};
       
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
        }); 
      },


    open(role) {
      
      this.title = "Welcome to the Ecosystem Manager";
     

      if(role) {
        this.form = JSON.parse(JSON.stringify(role));
        this.role = role;
        this.ok_btn_text = "Save";
        this.title = "Edit " + role.name;
        
      } else {
        
        this.form = {"name": "", "permissions": [], "active": true, "send_confirmation": true};
        this.role =  {"name": "", "permissions": [], "active": true, "send_confirmation": true};
      
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);

      
      this.$refs['onboardmodal'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/admin/roles`, this.form).then((res) => {
          console.log(res);
          location.href="/settings/roles";
        }, (res) => {
          this.$bvModal.msgBoxOk("Error: " +  res.body.detail);
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect
  }
}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';

.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}

.t.active {
  background-color: #29B3D2;
 

 
}
</style>
