import Vue from 'vue'
import Vuex from 'vuex'
import Config from '@/config'
import { getField, updateField } from 'vuex-map-fields';
//import VuexPersist from 'vuex-persist'
import _ from 'lodash';  

Vue.use(Vuex)

/*
const vuexPersist = new VuexPersist({
  key: 'hytools-backend',
  storage: window.localStorage
})
*/

let store =  new Vuex.Store({
  //plugins: [vuexPersist.plugin],
  previousRequest: null,
  state: {
      load_query_mode: false,
      monitor_page: 1,
      monitor_switch: false,
      monitor_date_from: "",
      monitor_date_to: "",
      user_cache: {},
      loading: false,
      selectedItems: [],
      selected_item: null,
      instance_users: [],
      instance_config: {},
      api_configs: [],
      sidebar_help: false,
      sidebar_visible: false,
      topic_type_cache: {},

      selectedTopic: null,
      usecase: {
        selected: null,
        filter: {
          custom_data: {industry: [], enterprise_function: [], vertical: []},
          project_id: [],
          keyword: [],
          watchlist_id: [],
          organization_id: [],
          number_projects: [null,null],
          number_organizations: [null,null],
          number_surveys: [null,null],
          updated_at: [],
          group_by: "industry",
          sort_by: null,
          sort_by_group: null
        },
       
      },

      survey: {
        selected: null,
        query: {
          filter: {
            'keywords': null,
            'project_id': null,
            'by_me': false,
            'my_projects': false
          }
        }
      },
      

      sidebar: {
        selectedProjectStage: null,
        selectedUseCase: null,
        selected_watchlist_id: null
      },
     
      instance: location.hostname.split(".")[0],

      currentOrg: null,
      currentEnrichments: null,
      currentProjectOrganizations: null,
      
      sidebar_project: {
        selected_item: null,
        selected_list: [],
        selected_watchlist_id: null
      },

      sidebar_usecase: {
        selected_item: null,
        selected_list: [],
        selected_watchlist_id: null
      },

      cluster: {
        query: {
          target_field: "cluster1", 
          method: "DBSCAN",
          number_of_clusters: 20,
          selected_fields: []
        }
      },

      news: {
        results: null,
        filter: {
          type_filter: ["investments", "news", "news_partner"],
          days: 31,
          q: [],
          sortBy: "_score",
          project_ids: [],
          topic_ids: [],
          page: 1
        }
      },

      topics: {
        q: "",
        results: null,
        trends: {},
        filter: {
          
        }
      },

      projects: {
        q: "",
        query: {
          is_project: true,
          filter: {
            
            bookmarked: false,
            joined: false,
            custom_data: {industry: [],enterprise_function: [], vertical: [], sub_vertical: []},
            keywords: [],
          
            
          },
        },
        organizations: "",
        results: null
      },
      filter_options: {},
      project_filter_options: {},
      query: {

        selected: "No similarity search",
        similar_list: [],
        advanced_query: "",
        advanced_query_mode: false,
        filter_base: null,
        filter_sim: null,
        filter_init: null,
        filter_compare_exclude: ["bookmark_ecosystem", "bookmark_hy", "custom_data"],
        query_type: "keywords",
        filter: {
            query_type: "keywords",
            add_name_to_full_query: true,
            query_mode: "OR",
            sim_description: null,
            name_list: null, 
            bookmark_ecosystem: null,
            bookmark_hy: null,
            sim_search: [],
            sim_search_neg: [],
            sortBy: "hy_rank",
            q: null,
            country_code: null,
            round_investment_type: null,
            region: null,
            city: null,
            crunchbase_category_groups_list: null,
            org_types: [],
            crunchbase_category_list: null,
            tags: null,
            categories: null,
            sim_domains: [],
            project_id: null,
            topic_ids: null,
            last_funding_on: [null,null],
            last_funding_on_from: null,
            last_funding_on_to: null,
            is_hr: null,
            is_funded_projects: null,
            employee_count: [],
            watchlist_id: null,
            number_of_funding_rounds: [null,null],
            total_funding_usd: [null,null],
            fundingTo: null,
            keywords: [],
            full_query: [],
            last_funding_type: null,
            founded_on_from: null,
            founded_on_to: null,
            current_revenue_usd_from: null,
            current_revenue_usd_to: null,
            sbti: null,
            cdp_score: null,
            custom_data: {
              industry: [], 
              enterprise_function: [], 
              vertical: [], 
              sub_vertical: [],
              use_case: []
            },
            sources: ["ecosystem"]
        }  
      },
      
      users: null,
      user: null,
      filter_data: {},
      search: {rows: null, filter_response: null, total_rows: null, loading: false},
      projectSearch: {rows: null, filter_response: null, total_rows: null},
      project: {
        filter: {"categorie": "All"},
        docId: null,
        project: null,
        rows: null,
        settings: {}
      }
  },

  constructor() {
    this.host = Config.config.base_host;
  },

  mutations: {
    updateField,
    change(state, docId) {

      const regex = /\/d\/(.*)\//mu;
      const str = docId;
      let m;
      if ((m = regex.exec(str)) !== null) {
          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
              console.log(`Found match, group ${groupIndex}: ${match}`);
              state.project.docId = match;
          });
      }  
      this.actions.getData();
    },
    
    LOAD_GRAPH (state, data) {
      
      data.settings.forEach((it) => { 
        if(!(it.field in state.project.settings)) {
          let values = it.value.split(",");
          values.unshift("All");
          state.project.settings[it.field] = {"values": values, "type": it.type};
        }
      });

      state.project.project = data;
    },


    GET_USERS (state) {
      
      if(state.users) return;
      
      state.users = [];
      
      Vue.http.get(`${Config.config.base_host}/users/search/autocomplete`).then(res => {
        state.users = res.body;       
      });
    },


    GET_FILTER_OPTIONS (state) {
      
      Vue.http.get(`${Config.config.base_host}/organizations/search/filter_options`).then(res => {
        res.body.tags_options.push(...state.instance_config.organization_types);
        res.body.tags_options = [...new Set(res.body.tags_options)];
        state.filter_options = res.body;
        console.log(state.filter_options);
        //state.filter_options.tags_options.push(...state.instance_config.organization_types);
       


      });
    },


    GET_PROJECT_FILTER_OPTIONS (state) {
      
      Vue.http.get(`${Config.config.base_host}/projects/search/filter_options`).then(res => {
        state.project_filter_options = res.body;       
      });
    },


    LOAD_SEARCH (state, data) {
      
      //state.search.rows = [];
     
      if(!state.query.filter.bookmark_ecosystem && !state.query.filter.bookmark_hy) {
        state.search.rows = [];
       
      }
      
      if(data && data.rows) data.rows.forEach((x) => { x.selected = false });
      state.query.filter.bookmark_ecosystem = data.bookmark_ecosystem;
      state.query.filter.bookmark_hy = data.bookmark_hy;
      state.search.total_rows = data.total_rows;
      state.search.spell_check = data.spell_check;
    
      if(data.rows) {
        //Array.prototype.push.apply(state.search.rows, data.rows);
        data.rows.forEach((x) => { 
          console.log(state.search.rows.length);
          state.search.rows.push(x);
        });
      }
      
    },

    LOAD_SEARCH_ANALYTICS (state, data) {
     
      state.searchAnalytics = {}; 
      state.searchAnalytics = data;

    },


    LOAD_PROJECTSEARCH (state, data) {
      state.projectSearch.rows = [];
      if(data && data.rows) data.rows.forEach((x) => { x.selected = false });
      state.projectSearch = data;
    },


    LOAD_USER (state, data) {
      
      state.user = data;
    },

    LOAD_CONFIG (state, data) {
      
      state.instance_config = data;
      
      if(state.instance_config.default_external_sources_filter) {
        if(state.query.filter.sources.indexOf("hy") == -1) {
          state.query.filter.sources.push("hy");
        } 
      }
    
    },

    LOAD_API_CONFIGS (state, data) {
      
      state.api_configs = data;
    
    },


    LOAD_SETTINGS (state, data) {
      state.project.settings = data;
    },

    LOADING (state, data) {
      state.loading = data;
    },

    SAVE_DATA (state, data) {
      console.log("S", state, data);
    },

    LOAD_FILTER_DATA (state, data) {
      state.filter_data = data;
    },


    LOAD_ORG (state, data) {
      state.currentOrg = data;
    },

    LOAD_PROJECT (state, data) {
      state.project.project = data.project;
      state.project.rows = data.project_organizations;
    },

    LOAD_PROJECT_PROJECT(state, data) {
      state.project.project = data.project;
    },

    LOAD_PROJECT_ORGANIZATIONS (state, data) {
      state.currentProjectOrganizations = data;
    },

    LOAD_ENRICHMENTS (state, data) {
      state.currentEnrichments = data;
    },

    DESELECT_ALL(state) {
      state.selectedItems = [];
    },

    SELECT_ALL(state) {
      state.search.rows.forEach((x) => { state.selectedItems.push(x); });
    },

    NEWS(state, data) {
      if(state.news.filter.page == 1) state.news.results = [];
      state.news.results = [];
      if(data) Array.prototype.push.apply(state.news.results, data);
      
    },

    PROJECTS(state, data) {
      state.projects.results = data
    },


    TOPICS(state, data) {
      data.forEach(it => {
        state.topics.trends[it._id] = null;
      });
      state.topics.results = data
      
    },

    RESET(state) {
      state.project.project = {};
      state.project.docId = null;
      state.search = {};
      state.query = {};
    },

    ADD_SELECTED(state, items) {
      items.forEach((item) => {
       
        state.selectedItems.push(item);
      
      });
    },

    REMOVE_SELECTED(state, items) {
      items.forEach((item) => {
     
        var index = state.selectedItems.map(x => {
          return x._id;
        }).indexOf(item._id);
        
        state.selectedItems.splice(index, 1); 
      });
    }




  },

  actions: {
   
    save_data({commit}, item) {
     //commit('SAVE_DATA', item);
     console.log(item);

     Vue.http.post(Config.config.base_host+'/projects/'+ this.state.project.docId+"/entries/"+item.uuid, item).then(response => {
        console.log(response);
        commit('LOADING', false);
        
     }, (x) => {alert("Problem: " + JSON.stringify(x))});

     
    },

    async getData({commit}){
      commit('LOADING', true);

      Vue.http.get(Config.config.base_host+'/projects/'+ this.state.project.docId,  {params: this.state.project.filter}).then(response => {
          commit('LOAD_GRAPH', response.body);      
          commit('LOADING', false);
      });

      Vue.http.get(Config.config.base_host+'/filter_data').then(response => {
          
        commit('LOAD_FILTER_DATA', response.body);

      });
    },


 

    async getNewsFeed({commit}, state){
      commit('LOADING', true);
      
      Vue.http.post(`${Config.config.base_host}/topics_v1/news_feed`, this.state.news.filter).then(res => {
       
        commit('NEWS', res.body.entries);

        if(state) {
          state.loaded();
          if(!res.body || res.body.length == 0) {
            state.complete();
          }
        }
        
        commit('LOADING', false);
      });

    },

    async getProjects({commit}){

      commit('LOADING', true);
      
      Vue.http.get(`${Config.config.base_host}/projects?q=${this.state.projects.q}`).then(res => {
        commit('PROJECTS', res.body);
        commit('LOADING', false);
      });

    },


    async getTopics({commit}){

      commit('LOADING', true);
      
      Vue.http.get(`${Config.config.base_host}/topics_v1?level=0`).then(res => {
        commit('TOPICS', res.body);
        commit('LOADING', false);
      });

    },

    async getUsers({commit}){
      commit('LOADING', true);
      
      Vue.http.get(`${Config.config.base_host}/projects?q=${this.state.projects.q}`).then(res => {
        commit('PROJECTS', res.body);
        commit('LOADING', false);
      });

    },

    async loadProject({commit}, project_id){
      commit('LOAD_PROJECT', {});
      commit('LOADING', true);
      
      Vue.http.get(`${Config.config.base_host}/projects/${project_id}`).then(res => {
        commit('LOAD_PROJECT', res.body);
        commit('LOADING', false);
        window.dispatchEvent(new Event('resize'));
      });

    },

    async loadProjectProject({commit}, project_data){
      commit('LOAD_PROJECT_PROJECT', project_data );
    },

    async loadTopic({commit}, topic_id){
      
      commit('LOADING', true);
      this.state.selectedTopic = null;
      
      Vue.http.get(`${Config.config.base_host}/topics_v1/${escape(topic_id)}`).then(res => {
        this.state.selectedTopic = res.body;
      
        commit('LOADING', false);
      });

    },


    async loadNewsletter({commit}, newsletter_id){
      commit('LOAD_NL', {});
      commit('LOADING', true);
      
      Vue.http.get(`${Config.config.base_host}/newsletter/${newsletter_id}`).then(res => {
        commit('LOAD_NL', res.body);
        commit('LOADING', false);
      });

    },

    load_query({commit}, {query_id, scroll_state}) {
     
      let all_rows = [];
      Vue.http.get(`${Config.config.base_host}/queries_v1/${query_id}/stats?page=${this.state.monitor_page}&date_from=${this.state.monitor_date_from}&date_to=${this.state.monitor_date_to}&filter=${this.state.monitor_switch}`).then(res => {
        if(!res.body.query_monitoring_results) res.body.query_monitoring_results = [];
        if(!this.state.search.rows) this.state.search.rows = [];
        all_rows = [...this.state.search.rows, ...res.body.query_monitoring_results]
        this.state.monitor_page++;
        
        if(scroll_state) {

          scroll_state.loaded();
        
          if(res.body.query_monitoring_results.length == 0) {
            scroll_state.complete();
          }

        }
  

        commit('LOAD_SEARCH', {"rows": all_rows, "bookmark_ecosystem": null, "bookmark_hy": null, "total_rows": res.body.stats ? res.body.stats.total_orgs : 0});
        commit('LOADING', false);
        
      });
    },

    save_nl({commit}, item) {
      //commit('SAVE_DATA', item);
      
      commit('LOADING', true);
      Vue.http.put(`${Config.config.base_host}/newsletter/${item._id}`, item).then(res => {
        commit('LOAD_NL', res.body);
        commit('LOADING', false);
      });
      
     },

     search_reset({commit}){
      this.state.search.rows = [];
      this.state.query.filter.bookmark_ecosystem = null;
      this.state.query.filter.bookmark_hy = null;
      this.state.search.total_rows = 0;
      this.state.search.spell_check = null;
      commit('LOADING', false);

     },  

      
    
    search({commit}, state){
      
      if(this.state.search.loading && this.previousRequest)  this.previousRequest.abort();
      
      this.state.search.loading = true;
      let vm = this;
     
      if(!this.state.query.filter.sim_search || this.state.query.filter.sim_search.length == 0) {
    
        commit('LOADING', true);
        Vue.http.post(Config.config.base_host+'/organizations/search', this.state.query, {
            before(request) {
              
              // abort previous request, if exists
              if (vm.previousRequest) {
                console.log(vm.previousRequest);
                //vm.previousRequest.abort();
              }

              // set previous request on Vue instance
              vm.previousRequest = request;
            }
          }
        ).then(response => {
            commit('LOAD_SEARCH', response.body);
            commit('LOADING', false);
            this.state.search.loading = false;
            if(state) {
              state.loaded();
              if(!response.body.rows || response.body.rows.length == 0) {
                state.complete();
              }
            }

            
        }).catch(() => {
          
         
          if(state) {
            state.loaded(); 
          }

          
        });


      } else {
        commit('LOADING', true);
        
        Vue.http.post(Config.config.base_host+'/organizations/search?similar=1', this.state.query).then(response => {
            commit('LOAD_SEARCH', response.body);
            commit('LOADING', false);
            this.state.search.loading = false;
            if(state) {
              state.loaded();
              if(!response.body.rows || response.body.rows.length == 0) {
                state.complete();
              }
            }

        });
      }

    },


    async searchAnalytics({commit}){
      
      commit('LOADING', true);
    
      Vue.http.post(Config.config.base_host+'/organizations/search?analytics=1', this.state.query).then(response => {
          commit('LOAD_SEARCH_ANALYTICS', response.body);
          commit('LOADING', false);
      });

    },

    async projectSearch({commit}, project_id){
      
      commit('LOADING', true);
      this.state.projects.query.filter.project_id = [project_id];
      this.state.projects.query.is_project = true;

      Vue.http.post(Config.config.base_host+'/organizations/search', this.state.projects.query).then(response => {
          commit('LOAD_PROJECTSEARCH', response.body);
          commit('LOADING', false);
      });
      /*Vue.http.post(Config.config.base_host+'/projects/'+project_id+'/flatten', this.state.projects.query).then(response => {
        commit('LOAD_PROJECTSEARCH', response.body);
        commit('LOADING', false);
    });*/

    },

    async loadOrg({commit}, {org_id, web_entity}){
      

      commit('LOAD_ORG', null);
      commit('LOADING', true);
   
      Vue.http.get(Config.config.base_host+'/organizations/'+org_id + (web_entity ? "?web_entity="+web_entity : "")).then(response => {
          commit('LOAD_ORG', response.body);
          commit('LOADING', false);
      });


      Vue.http.get(Config.config.base_host+'/organizations/'+org_id+'/project_organizations').then(response => {
        commit('LOAD_PROJECT_ORGANIZATIONS', response.body);
        commit('LOADING', false);
      });

      /*Vue.http.get(Config.config.base_host+'/organizations/'+org_id+'/enrichments').then(response => {
        commit('LOAD_ENRICHMENTS', response.body);
        commit('LOADING', false);
      });*/

     
    },


    async loadEnrichments({commit}, org_id){
      
      Vue.http.get(Config.config.base_host+'/organizations/'+org_id+'/enrichments').then(response => {
        commit('LOAD_ENRICHMENTS', response.body);
        commit('LOADING', false);
        });

    },

    addSelectedRecords({commit}){
      
      commit('LOADING', true);
      Vue.http.post(Config.config.base_host+'/projects/'+ this.state.project.docId+'/entries', {entries: this.getters.selectedItems}).then(response => {
          console.log(response);
          //commit('LOAD_SEARCH', response.body);
          commit('LOADING', false);
          commit('DESELECT_ALL');
      });

     
    },

    add_selected({commit}, items) {
      
      commit('ADD_SELECTED', items);
      
  
    },

    remove_selected({commit}, items) {

      commit("REMOVE_SELECTED", items);
     
  
    },

    selectAll({commit}) {
        
        commit('SELECT_ALL');
    
    },

    deselectAll({commit}) {
        
      commit('DESELECT_ALL');
  
    },

    async search_similar({commit}){
      
      commit('LOADING', true);
      Vue.http.post(Config.config.base_host+'/get_similar_next', {domains: this.state.query.domains}).then(response => {
        
          commit('LOAD_SEARCH', response.body.results);
          commit('LOADING', false);
      });

    },

    async start_loading({commit}){
      commit('LOADING', true);
    },

    async get_users({commit}){
      commit('GET_USERS');
    },

    async get_filter_options({commit}){
      commit('GET_FILTER_OPTIONS');
    },

    async get_projectfilter_options({commit}){
     
      commit('GET_PROJECT_FILTER_OPTIONS');
    },

    async stop_loading({commit}){
      commit('LOADING', false);
    },


  },

  modules: {
  
  },

  getters: {
    getField,
    loading: state => state.loading,
    selectedItems: state => { 
      return state.selectedItems;
    },

    docId: state => state.project.docId,
    project: state => state.project.project,
    projectRows: state => state.project.rows,
    news: state => state.news,
    
    settings: state => state.project.settings,
    search: state => state.search,
    projectSearch: state => state.projectSearch
    
  }

})

store.state.query.filter_base = _.cloneDeep(store.state.query.filter);
store.state.query.filter_sim = _.cloneDeep(store.state.query.filter);

export default store;