import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from './auth.js'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/dashboard' },
  { 
      path: '/', 
      redirect: '/dashboard' 
  },


 {
    path: '/search',
    name: 'search',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "submenu_search" */ '../views/QuickSearch.vue'),
    },
     
  },

  {
    meta: {requiresAuth: true},
    path: '/teams_config_external',
    components: {
      submenu: () => import(/* webpackChunkName: "projects" */ '../views/projects/TeamsConfiguration.vue'),
    }
  },
 

  
 

  {
    path: '/users',
    name: 'users',
    components: {
      submenu: () => import(/* webpackChunkName: "submenu_login" */ '../views/user/SubmenuLogin.vue'),
    },
      children: [{
        path: 'login',
        components: {
          main: () => import(/* webpackChunkName: "users_login" */ '../views/user/Login.vue'),
        }
      },
      
      {

        path: 'reset_password',
        components: {
          main: () => import(/* webpackChunkName: "users_login" */ '../views/user/Forgot.vue'),
        }
      },
      
      
      {
        path: 'password/:pwtoken',
        components: {
          main: () => import(/* webpackChunkName: "users_password" */ '../views/user/Password.vue'),
        }
      }]
  },

  {
    path: '/user_profiles',
    name: 'user_profiles',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "user_profile" */ '../views/user_profile/SubmenuUserProfile.vue'),
    },
      children: [{
        path: ':id',
        components: {
          main: () => import(/* webpackChunkName: "user_profile" */ '../views/user_profile/Profile.vue'),
        }
      }]
  },


  {
    path: '/dashboard',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/SubmenuDashboard.vue'),
    },
    
      children: [{
        path: '/',
        name: 'dashboard',
        redirect: 'main'
      },{
        path: 'main',
        name: 'main',
        components: {
          main: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard.vue'),
        }
      }]
  },


  {
    path: '/inbox',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "dashboard" */ '../views/inbox/SubmenuInbox.vue'),
    },
    
      children: [{
        path: 'main',
        name: 'inbox_main',
        components: {
          main: () => import(/* webpackChunkName: "dashboard" */ '../views/inbox/Inbox.vue'),
        }
      }]
  },

  

  {
    path: '/ecosystem/orgs/:org_id',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/SubmenuProfile.vue'),
      
    },
      children: [{
        path: '/',
        name: 'orgs',
        redirect: 'insights'
      },

     

      {
        path: 'insights',
        name: 'orgs_insights',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Profile.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'contacts',
        name: 'orgs_contacts',
        components: {
          main: () => import(/* webpackChunkName: "orgs_c" */ '../views/orgs/Contacts.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'news',
        name: 'orgs_news',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/News.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },



      {
        path: 'financials',
        name: 'orgs_financial',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Financial.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'funded_projects',
        name: 'orgs_funded_projects',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/FundedProjects.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'website',
        name: 'orgs_v',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Website.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'surveys',
        name: 'orgs_survey',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Surveys.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'benchmark',
        name: 'orgs_benchmark',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Benchmark.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },


      {
        path: 'sheet',
        name: 'orgs_sheet',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Sheet.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'extension',
        name: 'orgs_extension',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Profile.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'projects',
        name: 'orgs_projects',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Projects.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'projects/:id',
        name: 'orgs_project_profile',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/ProjectProfile.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'evaluations',
        name: 'orgs_evaluations',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Evaluations.vue'),
        }
      },

      {
        path: 'connect/:id',
        name: 'orgs_connect',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/ConnectTab.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },


      {
        path: 'similar',
        name: 'orgs_similar',
        components: {
         
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Similar.vue'),
          sideview: () => import(/* webpackChunkName: "orgs" */ '../views/ecosystem/SideList.vue')
        }
      },

      {
        path: 'files',
        name: 'orgs_files',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Profile.vue'),
        }
      },
      {
        path: 'admin',
        name: 'orgs_admin',
        components: {
          main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Admin.vue'),
        }
      },
    
    
    ]
  },

  {
    path: '/ecosystem',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SubmenuHealth.vue'),
     
    },
      
      children: [
        {
          path: 'lookup/:domain',
          name: 'orgs_lookup',
          components: {
            main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Lookup.vue'),
          }
        },
        {
          path: '/',
          name: 'ecosystem',
          redirect: '/ecosystem/list'
        },
        {
          path: 'list',
          name: 'ecosystem_list',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Health.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'health',
          name: 'ecosystem_health',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Health.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'analytics',
          name: 'ecosystem_analytics',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'comparison',
          name: 'ecosystem_comparison',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'benchmark',
          name: 'ecosystem_benchmark',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'map',
          name: 'ecosystem_map',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
        }
      }]
  },
  {
    path: '/health',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SubmenuHealth.vue'),
     
    },
      
      children: [
        {
          path: 'lookup/:domain',
          name: 'orgs_lookup',
          components: {
            main: () => import(/* webpackChunkName: "orgs" */ '../views/orgs/Lookup.vue'),
          }
        },
        {
          path: '/',
          name: 'ecosystem',
          redirect: '/health/list'
        },
        {
          path: 'list',
          name: 'ecosystem_list',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'health',
          name: 'ecosystem_health',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Health.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'analytics',
          name: 'ecosystem_analytics',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'comparison',
          name: 'ecosystem_comparison',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'benchmark',
          name: 'ecosystem_benchmark',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
          }
        },
        {
          path: 'map',
          name: 'ecosystem_map',
          components: {
            main: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/Ecosystem.vue'),
            sideview: () => import(/* webpackChunkName: "ecosystem" */ '../views/ecosystem/SideList.vue')
        }
      }]
  },


  {
    path: '/settings',
    meta: {requiresAuth: true, requiresAdmin: true},
    components: {
      submenu: () => import(/* webpackChunkName: "settings" */ '../views/settings/SubmenuSettings.vue'),
    },
      
      children: [
        {
          path: '/',
          name: 'settings',
          redirect: '/settings/admin'
        },

        {
          path: '/settings/reporting',
          name: 'settings_reporting',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/Reporting.vue'),
          }
        },

        {
          path: '/settings/admin',
          name: 'settings_admin',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/Admin.vue'),
          }
        },
        {
          path: '/settings/users',
          name: 'settings_users',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/Users.vue'),
          }
        },
        {
          path: '/settings/roles',
          name: 'settings_roles',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/Roles.vue'),
          }
        },
        {
          path: '/settings/datafields',
          name: 'settings_datafields',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/DataFields.vue'),
          }
        },
        {
          path: '/settings/taxonomy',
          name: 'settings_taxonomy',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/Taxonomy.vue'),
          }
        },
        {
          path: '/settings/connect',
          name: 'settings_connect',
          components: {
            main: () => import(/* webpackChunkName: "settings" */ '../views/settings/Connect.vue'),
          }
        }

       ]
  },

  {
    path: '/news',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "submenu_news" */ '../views/news/SubmenuNews.vue'),
    },
      
      children: [
        {
          path: '/',
          name: 'news',
          redirect: '/news/browse'
        },
        {
          path: 'browse',
          name: 'news_browse',
          components: {
            main: () => import(/* webpackChunkName: "news" */ '../views/topics/NewsOverview.vue'),
          }
        },
        {
          path: 'details/:topic_id',
          name: 'news_detail',
          components: {
            main: () => import(/* webpackChunkName: "news" */ '../views/topics/NewsDetail.vue'),
          }
        },
        {
          path: 'curate',
          name: 'news_curate',
          components: {
            main: () => import(/* webpackChunkName: "news" */ '../views/news/Curate.vue'),
        }
      }]
  },

  {
    path: '/wiki',
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "submenu_wiki" */ '../views/wiki/SubmenuWiki.vue'),
    },
      
      children: [

        {
          path: 'w/:id',
          name: 'wiki_browse',
          components: {
            main: () => import(/* webpackChunkName: "news_browse" */ '../views/wiki/Wiki.vue'),
          }
        },
        {
          path: 'w/:id/pages/:page',
          name: 'wiki_page',
          components: {
            main: () => import(/* webpackChunkName: "news_browse" */ '../views/wiki/Wiki.vue'),
          }
        },
        {
          path: ':id',
          name: 'wiki_browse_c',
          components: {
            main: () => import(/* webpackChunkName: "news_browse" */ '../views/wiki/Wiki.vue'),
          }
        },
        {
          path: ':id/pages/:page',
          name: 'wiki_page_c',
          components: {
            main: () => import(/* webpackChunkName: "news_browse" */ '../views/wiki/Wiki.vue'),
          }
        }
       
      ]
  },

  {
    path: '/web',
    
    components: {
      submenu: () => import(/* webpackChunkName: "website" */ '../views/website/SubmenuWebsite.vue'),
    },
      
      children: [

        {
          path: 'index',
          name: 'web_index',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Index.vue'),
          }
        },

        {
          path: 'health',
          name: 'web_health',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Health.vue'),
          }
        },

        {
          path: 'energy',
          name: 'web_energy',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Energy.vue'),
          }
        },

        {
          path: 'corporates',
          name: 'web_corporates',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Corporates.vue'),
          }
        },
        {
          path: 'investors',
          name: 'web_investors',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Investors.vue'),
          }
        },
        {
          path: 'consultants',
          name: 'web_consultants',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Consultants.vue'),
          }
        },
        

        {
          path: 'contact',
          name: 'web_contact',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Contact.vue'),
          }
        },
        {
          path: 'imprint',
          name: 'web_imprint',
          components: {
            main: () => import(/* webpackChunkName: "website" */ '../views/website/Imprint.vue'),
          }
        }
       
      ]
  },
  {
    path: '/surveys_external',
    name: 'survey_external',
    components: {
      submenu: () => import(/* webpackChunkName: "surveys" */ '../views/surveys/SubmenuSurvey.vue'),
    },
    children: [
      
      {
        path: ':link_token',
        name: 'survey_external_path',
        components: {
          main: () => import(/* webpackChunkName: "external" */ '../views/surveys/External.vue'),
          //sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
       }
      }
    ]
  },

  {
    path: '/surveys',
    
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "surveys" */ '../views/surveys/SubmenuSurvey.vue'),
    },
      children: [
      
        {
          meta: {requiresAuth: true},
          path: '/',
          name: 'main',
          redirect: {"name": "survey_dashboard"} 
        
        },
        {
          meta: {requiresAuth: true},
          path: 'dashboard',
          name: 'survey_dashboard',
          components: {
            main: () => import(/* webpackChunkName: "surveys" */ '../views/surveys/SurveyDashboard.vue'),
            sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
          }
        },
        {
          meta: {requiresAuth: true},
          path: 'templates',
          name: 'survey_templates',
          components: {
            main: () => import(/* webpackChunkName: "templates" */ '../views/surveys/Templates.vue'),
            sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
          }
        },
        {
          meta: {requiresAuth: true},
          path: 'tracking',
          name: 'survey_tracking',
          components: {
            main: () => import(/* webpackChunkName: "templates" */ '../views/surveys/Tracking.vue'),
            sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
          }
        },
        {
          meta: {requiresAuth: true},
          path: 'details',
          name: 'survey_details',
          components: {
            main: () => import(/* webpackChunkName: "templates" */ '../views/surveys/Tracking.vue'),
            sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
          }
        },
       
    ]
  },





  {
    path: '/topics',
    
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "topics" */ '../views/topics/SubmenuTopic.vue'),
    },
      children: [
      
      {
        meta: {requiresAuth: true},
        path: '/',
        name: 'topics',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/Overview.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: 'news',
        name: 'news',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/NewsOverview.vue'),
        }
      
      },
      {
        meta: {requiresAuth: true},
        path: 'usecases',
        name: 'usecases',
        redirect: '/topics/usecases/list' 
      
      },
      {
        meta: {requiresAuth: true},
        path: 'usecases/list',
        name: 'usecases',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/UseCases.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      {
        meta: {requiresAuth: true},
        path: 'usecases/compare',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/UseCases.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: 'usecases/:topic_id',
        redirect: { name: 'usecase_list' }
      },

      {
        meta: {requiresAuth: true},
        path: 'usecases/:topic_id/list',
        name: "usecase_list",
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/UseCaseDetail.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },

    
      {
        meta: {requiresAuth: true},
        path: 'details/:topic_id',
        name: "topics_list",
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/TopicOverview.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      // {
      //   meta: {requiresAuth: true},
      //   path: 'beta/:topic_id',
      //   name: "topics_list",
      //   components: {
      //     main: () => import(/* webpackChunkName: "topics" */ '../views/topics/DetailNew.vue'),
      //     sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
      //   }
      // },
      {
        meta: {requiresAuth: true},
        path: 'add_topic',
        name: "topics_list",
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/TopicOverview.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },

      {
        meta: {requiresAuth: true},
        path: 'usecases/:topic_id/assessements',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/Assessments.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      {
        meta: {requiresAuth: true},
        path: 'usecases/:topic_id/surveys',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/Surveys.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      {
        meta: {requiresAuth: true},
        path: 'usecases/:topic_id/matching',
        components: {
          main: () => import(/* webpackChunkName: "topics" */ '../views/topics/Surveys.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      }
      
    ]
  },

  {
    path: '/projects',
    
    meta: {requiresAuth: true},
    components: {
      submenu: () => import(/* webpackChunkName: "projects" */ '../views/projects/SubmenuProject.vue'),
    },
      children: [
      
      {
        meta: {requiresAuth: true},
        path: '/',
        name: 'projects',
        components: {
          //filterview: () => import(/* webpackChunkName: "projects" */ '../views/projects/ProjectOverviewFilter.vue'),
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Overview.vue'), 
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      
      },

      {
        
        path: 'redirect',
        name: 'projects_redirect',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Redirect.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },
      {
        meta: {requiresAuth: true},
        path: ':id',
        redirect: {"name": "project_org_board"}
      
      },
      {
         meta: {requiresAuth: true},
         path: ':id/dashboard',
         name: 'project_dashbaord',
         components: {
           main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Dashboard.vue'),
           sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
         }
      
      },
      

      {
        meta: {requiresAuth: true},
        path: ':id/usecases',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/UseCases.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: ':id/general/board',
        name: 'project_idea_board',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/GeneralBoard.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: ':id/general/list',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/GeneralList.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },


      {
        meta: {requiresAuth: true},
        path: ':id/surveys',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Surveys.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: ':id/surveys_standard',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/SurveysStandard.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: ':id/survey_templates',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/SurveyTemplatesStandard.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: ':id/survey_templates/new',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/SurveyTemplatesStandard.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

      {
        meta: {requiresAuth: true},
        path: ':id/survey_templates/:survey_template_id',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/SurveyTemplatesStandard.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },




      {
        meta: {requiresAuth: true},
        path: ':id/matching',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Surveys.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue'),
        }
      
      },

     
      {
        meta: {requiresAuth: true},
        path: ':id/usecases/:usecase_id',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/UseCase.vue'),
          
        }
      
      },
      {
        meta: {requiresAuth: true},
        path: ':id/news',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/News.vue'),
        }
      
      },

      {
        path: ':id/stage_analytics',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/StageAnalytics.vue'),
        }
      },

      {
        path: ':id/members',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Members.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },


      {
        meta: {requiresAuth: true},
        name: 'project_org_board',
        path: ':id/board',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Board.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      
      },
      {
        meta: {requiresAuth: true},
        path: ':id/list',
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/List.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      
      },
      {
        path: ':id/analysis',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Analysis.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      {
        path: ':id/analytics',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Analytics.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      
      {
        path: ':id/topics',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Topics.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },
      {
        path: ':id/radar',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Radar.vue'),
          sideview: () => import(/* webpackChunkName: "projects" */ '../views/projects/SideList.vue')
        }
      },

      {
        path: ':id/setup',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Setup.vue'),
        }
      },
      {
        path: ':id/cluster',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Cluster.vue'),
        }
      },

      {
        path: ':id/network',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Network.vue'),
        }
      },
      {
        path: ':id/map',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Map.vue'),
        }
      },
      {
        path: ':id/browser',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Browser.vue'),
        }
      },
      {
        path: ':id/redirect',
        meta: {requiresAuth: true},
        components: {
          main: () => import(/* webpackChunkName: "projects" */ '../views/projects/Redirect.vue'),
        }
      }]
  },

  {
    path: '/settings',

    name: 'settings',
    meta: {requiresAuth: true, requiresAdmin: true},
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },


]




const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

if(location.href.indexOf('wiki.hy') > -1) {
  document.title = "hy Wiki";
}

router.beforeEach((to, from, next) => {
 
 
  if((location.host == "ecosystem-manager.co" || location.host == "www.ecosystem-manager.co") && to.fullPath == "/dashboard/main") {
    location.href = "https://ecosystem-manager.co/web/index";
    return;
  }

  store.dispatch("stop_loading");
  auth.checkAuth().then(() => {

    if(to.matched.some(record => record.meta.requiresAdmin) && !auth.has_access("admin", null, "read")) {
      next({ path: '/'});
    }
  
  
    if((location.host == "ecosystem-manager.co" || location.host == "www.ecosystem-manager.co") && to.fullPath == "/dashboard/main") {
      location.href = "https://ecosystem-manager.co/web/index";
      return;
    }

    if(location.href.indexOf('wiki.hy') > -1) {
      document.title = "hy Wiki";
    }

    if (to.name !== 'wiki_browse' && to.name !== 'wiki_page' && location.href.indexOf('wiki.hy') > -1 && location.href.indexOf("/wiki/") == -1) {
      location.href="/wiki/w/1LGilBI0PBbNryAD9hq6YSXMhspbh8_vz";
    } else {
      next();
    }
  }, () => {
    if(to.matched.some(record => record.meta.requiresAuth) && !auth.user.authenticated) {
        if (to.name !== 'wiki_browse' && to.name !== 'wiki_page' && location.href.indexOf('wiki.hy') > -1 && location.href.indexOf("/wiki/") == -1) {
          location.href="/wiki/w/1LGilBI0PBbNryAD9hq6YSXMhspbh8_vz";
        }
        if(from.path != '/users/login') {
          next({ path: '/users/login', query: { next: to.fullPath }})
        }
        
        
      } else {
        if (to.name !== 'wiki_browse' && to.name !== 'wiki_page' && location.href.indexOf('wiki.hy') > -1 && location.href.indexOf("/wiki/") == -1) {
            location.href="/wiki/w/1LGilBI0PBbNryAD9hq6YSXMhspbh8_vz";
        } else {
          next();
        }
        
      }
  });
  

});


export default router