  <template lang="pug">
  
  .filter-group
    b-overlay(:show="init_loading")
      template(v-if="options")
        
          //b-select(:options="options", size="sm", v-model="value")
          v-select(placeholder="Select Use Case" , :taggable="false", :id="id", :multiple="multiple", :disabled="disabled", label="name", v-model="usecase_id" :options="options", :filterable="true", :reduce=" x => x._id", :close-on-select="closeOnSelect", :select-on-tab="true")
              //template(slot="no-options") Type to search ...
              template(#no-options="{ search, searching, loading }") No use case assigned.

          b-card(v-if="options && options.length > 0")
            | {{ ma }}
                 
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'

export default {
  name: 'UseCaseSelect',
  props: {
    "project_id": {
        default: null,
        required: true
    },
    "show_type": {
        type: Boolean,
       default: false
    },
    "value":  {
      default: null
    }, 
    "id":  {
      default: "UserMultiSelect"
    }, 
    "collpased": {
      type: Boolean,
      default: false
    },
    "closeOnSelect": {
      type: Boolean,
      default: true
    },
    "multiple": {
      type: Boolean,
      default: false
    },
    "disabled": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: null,
        usecase_id: null,
        init_loading: false
       
    }
  },


  watch: {
        usecase_id: function(val) {
          this.$emit("input", val);
        },

        project_id: function() {
          this.usecase_id = null;
          this.options = null;
          this.cleanSearch();
        }
  },


  mounted: function() {
     this.usecase_id = this.value;
     this.uid = this._uid;
     this.cleanSearch();
  },

  computed: {
    ma: function() {
      if(this.options.filter((x)=>{ return this.value == x._id }).length == 0) return "";
      return (this.value ? this.options.filter((x)=>{ return this.value == x._id })[0]['custom_data']['short_description'] : "");
    }
  },

  methods: {
    change(event) {
      alert(event);
    },

    cleanSearch() {
        this.init_loading = true;
        let vm = this;
        //Vue.http.get(`${Config.config.base_host}/projects/${escape(this.project_id)}`).then(res => {
         Vue.http.get(`${Config.config.base_host}/projects/${escape(this.project_id)}`).then(res => {
          this.init_loading = false;
          vm.options = res.body.topics.map((x) => { return x.relations['topic_id'] });
   
      });

    },
    onSearch(search, loading) {
        loading(true);
        
        this.search(search, loading, this);
    },

    search: debounce((search, loading, vm) => {
      loading(true);
      Vue.http.get(`${Config.config.base_host}/projects/${escape(this.project_id)}`).then(res => {
          
          vm.options = res.body.topics.map((x) => { return x.relations['topic_id'] });
        
        loading(false);
      });

    }, 350)
  },

  components: {
    
  }
}

</script>
<style lang="scss">

</style>
