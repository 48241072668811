<template lang="pug">
  .filter-group.w-100
    template(v-if="isBig")
      div(class="modal-backdrop", style="z-index: 1030", @click="$emit('search'); isBig=false")
    .w-100(v-bind:class="{big: isBig && !embed, bign: isBig && embed}", style="z-index: 1035")
      
      
      .d-flex().w-100
        div.w-100.flex-grow-1(v-if="!isBig", @clicl="isBig=true")
         
          b-form-textarea( @focus="isBig=true", :value="(value ? value.join(' ') : '')", rows="1", :placeholder="placeholder").small.w-100

        div.w-100.flex-grow-1(v-if="isBig")
          //b-form-radio-group(id="radio-group-1", v-model="query_mode", :options="['OR', 'AND', 'Custom']")
          
          hy-select(@search:focus="isBig=true", @search:close="isBig=false", :taggable="true", ref="uo" v-model="value", :clearable="true" :id="id", label="label" :multiple="true", :options="options", :filterable="false", placeholder="Enter a keyword and press enter" @search="onSearch", @fullsearch="$emit('search')" :select-on-tab="true" :close-on-search="false")
              
              template(slot="no-options") 
                template(v-if="!value.length")
                  | Start typing to get keyword suggestions, press enter to select a keyword.
                template(v-if="value.length")
                  | Type more keywords or press enter to search

              template(slot="selected-option" slot-scope="option")

                template(v-if="['AND', 'OR', 'NOT'].indexOf(option.label) > -1")
                  .op
                  
                    b-select(:id="option.key" :key="option.key" @change="changeOption(option)" v-model="option.label", :options="['AND', 'OR', 'NOT']", size="sm", style="background-color: transparent; border: none;")
                     
                  
                template(v-else-if="['(', ')'].indexOf(option.label) > -1")
                  span {{ option.label }} 
                template(v-else)
                  .keyword
                    span {{ option.label }}
                      

              template(slot="option" slot-scope="option")
                template(v-if="['AND', 'OR', 'NOT'].indexOf(option.label) > -1")
                  | {{ option.label }}
                  span.text-muted  (Operator)
                template(v-else)
                  | {{ option.label }}

          .recommendations( v-if="showRecommendations && isBig").mt-1
    
            b-overlay(:show="suggest_loading", size="sm", spinner-type="grow", :spinner-small="true") 
              .small.text-muted.d-inline(v-if="recommendations.length") Suggestions: 
              .badge.badge-secondary(@click="value.push(it[0])").d-inline-block.mr-1(v-for="it in recommendations.slice(0,15)")  {{it[0]}}


          .d-flex.flex-row.flex-wrap.w-100.mt-4(v-if="isBig")
            b-btn(size="sm", variant="outline-secondary", @click="value.push('OR')") OR
            b-btn(size="sm", variant="outline-secondary", @click="value.push('AND')").ml-1 AND
            b-btn(size="sm", variant="outline-secondary", @click="value.push('NOT')").ml-1 NOT
            b-btn(size="sm", variant="outline-secondary", @click="value.push('(')").ml-1 (
            b-btn(size="sm", variant="outline-secondary", @click="value.push(')')").ml-1 )
       

            .ml-auto
              b-input-group().align-middle.align-items-center
                b-radio(name="bool", v-model="query_mode_internal", value="OR", :disabled="query_custom_fix").align-items-center.mr-3
                  .align-middle(style="margin-top: 3px") OR
                b-radio(name="bool", v-model="query_mode_internal", value="AND", :disabled="query_custom_fix").mr-3
                  .align-middle(style="margin-top: 3px") AND

                b-radio(name="bool", v-model="query_mode_internal", value="Custom").mr-3
                  .align-middle(style="margin-top: 3px") Custom
                    i(v-b-tooltip="'Use AND/OR/NOT operators and brackets to create advanced queries. E.g. \"artificial intelligence AND (warehousing OR logistics)\"'").far.fa-question-circle.ml-1

                b-btn(variant="outline-secondary", size="sm", @click="$emit('search', query_mode_internal); isBig=false").ml-3 Search

       

          
         

   
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Logo from '@/components/Logo'
export default {
  name: 'CompanySelect',
  props: {
    "value": {
      default: null
    },
    "query_mode": {
      default: 'OR'
    },
    "id": {
      default: null
    },
    "showRecommendations": {
      type: Boolean,
      default: true
    },
    "embed": {
      type: Boolean,
      default: true
    },
    "placeholder": {
      default: "Enter a keyword and press enter"
    },  
  },
  data: function () {
    return {
        uid: null,
        isBig: false,
        options: [],
        recommendations: [],
        suggest_loading: false,
        previousRequest: false,
        previousRequestSuggest: false,
        query_mode_internal: "OR",
        query_custom_fix: false

    }
  },
  mounted: function() {
     this.uid = this._uid;
     this.query_mode_internal = this.query_mode;
     this.recommend();
  },
     
  watch: {
        query_mode: function(val) {
          this.query_mode_internal = val;
        },
        query_mode_internal: function(val) {
          this.$emit("query_mode_change", val);
        },
        value: function(val) {
          
          if (val && val.length) {
            if(val.indexOf("OR") > -1 || val.indexOf("AND") > -1 || val.indexOf("NOT") > -1) {
              this.query_mode_internal = "Custom";
              this.query_custom_fix = true;
            } else {
              
              this.query_custom_fix = false;
            }
          } else {
            this.query_custom_fix = false;
          }
          this.$emit("input", val);
          this.recommend();
        }
  },
  methods: {
  selecting(value) {
    console.log(value);
    return value+"jj";
  }, 

  changeOption(option) {
   
    let nummer = option.key.replace("AND", "").replace("OR", "").replace("NOT", "");
   
   

    for (let index = 0; index <  this.value.length; index++) {
      if (index === parseInt(nummer)) {
     
        this.value[index] = option.label;
        break;
      }
    } 

    this.$forceUpdate();
  },

  recommend(){
      
      if(!this.value) return;
      if(this.previousRequest) this.previousRequest.abort();

      this.suggest_loading = true;
      this.recommendations = []
      let vm = this;
      let value = this.value.filter((x) => x != "OR" && x != "AND" && x != "NOT" && x != "(" && x != ")");
     
      if(!value || value.length == 0) {
        this.suggest_loading = false;
        return;
      }
      Vue.http.post(`${Config.config.base_host}/service/query_expansion`, {"terms": value}, {

          // use before callback
          before(request) {
           
            // abort previous request, if exists
            if (vm.previousRequest) {
              vm.previousRequest.abort();
            }

            // set previous request on Vue instance
            vm.previousRequest = request;
          }
      }).then(res => {
        this.suggest_loading = false;
        this.recommendations = res.body;
        
      });
  },

  resize() {
    
  },

   onSearch(search, loading) {
    
   
      loading(true);
     
      this.options = [search];
      this.search(loading, search, this);
    },
    search: debounce((loading, search, vm) => {
      if (vm.previousRequestSuggest) {
        vm.previousRequestSuggest.abort();
      }

      if(search) {
        if(search == "(") {
          vm.options.push("(");
          loading(false);
        }
        else if(search == ")") {
          vm.options.push(")");
          loading(false);
        } else {
          
          if(search.toLowerCase() == "a" || search.toLowerCase() == "an" ) vm.options.push("AND");
          if(search.toLowerCase() == "o" || search.toLowerCase() == "or" ) vm.options.push("OR");
          if(search.toLowerCase() == "n" || search.toLowerCase() == "no" ) vm.options.push("NOT");
          
          Vue.http.post(`${Config.config.base_host}/service/autocomplete`, {"term": search}, {

            // use before callback
            before(request) {
            
              // abort previous request, if exists
              if (vm.previousRequestSuggest) {
                vm.previousRequestSuggest.abort();
              }

              // set previous request on Vue instance
              vm.previousRequestSuggest = request;
            }
          }).then(res => {
          let options = res.body;

          vm.options.push(...options);
         
          
          loading(false);
        });
        }
      } else {
        vm.options = [];
       
        
      }


      loading(false);
 
    }, 250)
  },
  components: {
    Logo
  }
}
</script>
<style lang="scss" scoped>
@import "@/_vars.scss";
.recommendations {
  .badge {
    cursor: pointer;
  }
}

.big {
  position: fixed;
  width: 700px  !important;
  z-index: 1000;
  background-color: $gray-200;
  
  padding: $pad;
}

.bign {
  position: relative;
  z-index: 1000;
  background-color: $gray-200;
  padding: $pad;
}

.op {
  color: $red;
}
.keyword {
  color: $primary !important;
}

</style>