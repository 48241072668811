
<template lang="pug">
    .main
        template(v-if="url")
            div(:style="{'background': 'url(https://images.hytechnologies.co/unsafe/'+size+'x'+size+'/logo.clearbit.com/' + url, 'width': size + 'px', 'height': size  + 'px'}").feed-image
        template(v-else-if="domain")
            div(:style="{'background': 'url(https://images.hytechnologies.co/unsafe/'+size+'x'+size+'/logo.clearbit.com/' + domain, 'width': size + 'px', 'height': size  + 'px'}").feed-image
        template(v-else-if="web_entity")
            div(:style="{'background': 'url(https://images.hytechnologies.co/unsafe/'+size+'x'+size+'/logo.clearbit.com/' + web_entity, 'width': size + 'px', 'height': size  + 'px'}").feed-image
        template(v-else-if="name")
            avatar(:username="name", :size="size", :inline="true", :rounded="rounded", :backgroundColor="getColor(name)").mr-1 
        template(v-else)
              avatar(:username="' '", :size="size", :inline="true", , :rounded="rounded", backgroundColor="grey").mr-1 
        
</template>

<script>
// @ is an alias to /src

//import Config from '@/config'
import Avatar from 'vue-avatar'


export default {
  name: 'CompanySelect',
  props: {
    "web_entity":  {
      default: null
    },

    "domain":  {
      default: null
    },
   
    "name": {
     
      default: null
    }, 

    "url": {
     
      default: null
    },

    "size": {
      default: 50
    },
     "rounded": {
      type: Boolean,
      default: true
    }


  },
  data: function () {
    return {
        uid: null,
        
    }
  },

  mounted: function() {
      this.uid = this._uid;

  }, 

  methods: {
    
      getColor(name) {
        //let colors = ['#43B02A', '#26890D', '#2C5234', '#0076A8', '#041E42', '#012169', '#0097A9', '#007680', '#004F5'];
        let colors = ['#26b4d2', '#f76664', '#f7d864', '#7a6ecc', '#4ac596'];
        return colors[name.length % (colors.length)];
      }

  },

  components: {
    Avatar 
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    width: 50px;
    height: 50px;
}
</style>
