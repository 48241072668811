

<template lang="pug">
    
    .main.d-inline-block
     
        template(v-if="popover && internal_user")
          
          b-link(:to="'/user_profiles/' + internal_user._id")

          .pop(v-b-popover.hover.top="internal_user.email" :title="internal_user.full_name")
             
              template(v-if="internal_user.profile_pic")
                  b-img(v-set-image-src="internal_user.profile_pic", :style="{width: size +'px', height: size +'px', borderRadius: '5px', border: 'none'}", referrerPolicy="no-referrer").feed-image
              template(v-else-if="internal_user.image_payload")
                  b-img(:src="internal_user.image_payload", :style="{width: size +'px', height: size +'px', borderRadius: '5px', border: 'none'}", referrerPolicy="no-referrer").feed-image
              template(v-else)
                  avatar(:username="internal_user.full_name", :size="size", :inline="true", :rounded="false").mr-1 
              template(v-if="showName")
                  .d-inline-block.ml-2 {{internal_user.full_name}}
              template(v-if="showEmail")
                  .d-inline-block.ml-2 
                    a(:href="'mailto:'+internal_user.email+'?subject='+(emailSubject)") {{internal_user.email}}

        template(v-if="!popover && internal_user")
         
              template(v-if="internal_user.profile_pic")
                  b-img(v-set-image-src="internal_user.profile_pic", :style="{width: size +'px', height: size +'px', borderRadius: '5px', border: 'none'}", referrerPolicy="no-referrer").feed-image
              template(v-else-if="internal_user.image_payload")
                  b-img(:src="internal_user.image_payload", :style="{width: size +'px', height: size +'px', borderRadius: '5px', border: 'none'}", referrerPolicy="no-referrer").feed-image
              template(v-else)
                  avatar(:username="internal_user.full_name", :size="size", :inline="true", :rounded="false").mr-1 
              
              template(v-if="showName")
                  .d-inline-block.ml-2 {{internal_user.full_name}}
              template(v-if="showEmail")
                  .d-inline-block.ml-2 
                    a(:href="'mailto:'+internal_user.email+'?subject='+(emailSubject)") {{internal_user.email}}
        
</template>

<script>
// @ is an alias to /src

//import Config from '@/config'
import Avatar from 'vue-avatar'
import Config from '@/config'
import Vue from 'vue'

export default {
  name: 'User',
  props: {
    "user":  {
      default: null
    },

    "user_id":  {
      default: null
    },

    "popover": {
      default: true
    },

    "showName": {
      default: false
    },

    "showEmail": {
      default: false
    },

    "emailSubject": {
      default: ""
    },
   
    "size": {
     
      default: 30
    }

  },
  data: function () {
    return {
        base_host: null,
        uid: null,
        internal_user: null
    }
  },
  watch: {
    'user_id': function() {
        Vue.http.get(`${Config.config.base_host}/users/lookup/${this.user_id}`).then(res => {
          this.internal_user = res.body;
        
      });
    }
  },

  mounted: function() {
    this.base_host = Config.config.base_host;
     this.uid = this._uid;
     if(!this.user && this.user_id) {
       if(!this.$store.state.user_cache[this.user_id]) {
         Vue.http.get(`${Config.config.base_host}/users/lookup/${this.user_id}`).then(res => {
            this.$store.state.user_cache[this.user_id] =  res.body;
            this.internal_user = res.body;
        });

       } else {
         this.internal_user = this.$store.state.user_cache[this.user_id];
       }
       
     } else {
       this.internal_user = this.user;
     }
  
  }, 

     
  
  methods: {
    
  },

  components: {
    Avatar 
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    width: 50px;
    height: 50px;
    background-color: #333  !important;
}


</style>
