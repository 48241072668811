
var hostname = window.location.hostname;

var BASE_HOST = window.location.protocol + "//" + hostname + "/api";

// Local dev mode
if(location.href.indexOf("localdev") > -1 || location.href.indexOf(".local") > -1){
    
    BASE_HOST = "https://localdev.ecosystem-manager.co:8090";
    //BASE_HOST = "https://hydemo.ecosystem-manager.co/api";
}

export default {
    config: {
        "base_host": BASE_HOST
    }
}   
