<template lang="pug">

.h-pin()
  
  b-btn(v-if="$store.state.user.pins.map(x => x.item_id).indexOf(item_id) == -1", size="sm", :variant="variant", @click="add_pin()", :key="item_id").h-100.w-100
    slot()
      
 
  b-btn( v-if="$store.state.user.pins.map(x => x.item_id).indexOf(item_id) > -1", size="sm", variant="secondary", @click="remove_pin()" :key="item_id + 'is'").h-100.w-100.h-mouseover-parent
    .h-mouseover-show
      slot(name="unfollow")
    .h-mouseover-hide
      slot(name="following")

</template>

<script>

export default {
  
  name: 'HPin',
  
  props: {
    
    item_id: {
      type: String,
      required: true
    },
    item_type: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: false,
      default: 'outline-secondary'
    }
  },

  data: function () {
    return {
      uid: null
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },
  
  methods: {

      add_pin() {
        this.$store.state.user.pins.unshift({"item_id": this.item_id, "item_type": this.item_type});
        this.$auth.saveUserData(this.$store.state.user).then(()=> {
          this.$auth.get_pins().then(() => {
            this.update();
          });
        });
        
      },

      remove_pin() {
        this.$store.state.user.pins = this.$store.state.user.pins.filter(x => x.item_id != this.item_id);
        this.$auth.saveUserData(this.$store.state.user).then(()=> {
            this.update();
        });
      },


      update() {
        this.$emit("update");
      }
  },

  components: {
    
  }

}

</script>

<style scoped lang="scss">
  a:hover {
    text-decoration: none !important;
  }
</style>