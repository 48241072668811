<template lang="pug">
  .filter-group
 
    v-select(label="name", :options="options", :filterable="false",  @search="onSearch", :class="{sm: size=='sm'}")

</template>

<script>
// @ is an alias to /src
//import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
export default {
  name: 'CompanySelect',
  props: {
    "value":  {
      default: null
    }, 
    "size": {
      type: String,
      default: "md"
    }
  },
  data: function () {
    return {
        uid: null,
        options: []
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

     
  
  methods: {
    
   onSearch(search, loading) {
      
      //loading(true);
      this.search(loading, search, this);

    },

    search:(loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/organizations/search/autocomplete?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        loading(false);
      });

    }
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}

.sm {
  font-size: 0.88rem;
}
</style>
