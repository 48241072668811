<template lang="pug">
  <div>
    bubble-menu(
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor"
    )
      b-btn(@click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }", variant="dark") Bold

      b-btn(@click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }", variant="dark") Italic
    
      b-btn(@click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }", variant="dark") Strike

    floating-menu(
      class="floating-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor"
    )
      b-btn(@click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }", variant="dark") H1
  
      b-btn(@click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }", variant="dark") H2
    
      b-btn(@click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }", variant="dark") Bullet List

    

    <editor-content :editor="editor" />
  </div>
</template>

<script>

import {
  Editor,
  EditorContent,
  BubbleMenu,
  FloatingMenu,
} from '@tiptap/vue-2'


import StarterKit from '@tiptap/starter-kit'

export default {

  name: 'HEditor',

  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    StarterKit
  },
  props: ['value'],
  data() {
    return {
      editor: null,
    }
  },

   mounted: function() {
    let vm = this;
    this.editor = new Editor({
      onUpdate({ editor }) {
        vm.$emit("input", editor.getHTML())
      },
      extensions: [
        StarterKit
      ],
      content: this.value,
    })
  },

  beforeDestroy: function() {
    this.editor.destroy()
  },
}

</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  max-height: 400px;
  overflow-y: auto;
  > * + * {
    margin-top: 0.1em;
  }


  border: 1px solid silver;

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.9;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    margin-right: 0.2rem;
    opacity: 0.1;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>