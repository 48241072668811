  <template lang="pug">
  
  .filter-group

    template(v-if="options && options.length")
      v-select(:taggable="false", :id="id", :multiple="multiple", :disabled="disabled", label="full_name", v-model="value" :options="options", :filterable="true", :reduce=" x => x._id" ,ref="selectBox", :close-on-select="closeOnSelect", :select-on-tab="true")
          template(slot="no-options") Type to search
          template(slot="option" slot-scope="option")
            .media.option
              template(v-if="option.profile_pic")
                  b-img(rounded="circle", :src="option.profile_pic", :style="{width: 20 +'px', height: 20 +'px'}", referrerPolicy="no-referrer").mr-2.feed-image
              template(v-else)
                  avatar(:username="option.full_name", :size="15", :inline="true", :rounded="true").mr-2
              .media-body
                .small {{ option.full_name }}
               
          template(slot="selected-option" slot-scope="option")
              .media.option
                template(v-if="option.profile_pic")
                  b-img(rounded="circle", :src="option.profile_pic", :style="{width: 20 +'px', height: 20 +'px'}", referrerPolicy="no-referrer").mr-2.feed-image
                template(v-else)
                  avatar(:username="option.full_name", :size="15", :inline="true", :rounded="true").mr-2
                .media-body
                  .small {{ option.full_name }}
                 
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'UserMultiSelect',
  props: {
    "value":  {
      default: null
    }, 
    "id":  {
      default: "UserMultiSelect"
    }, 
    "collpased": {
      type: Boolean,
      default: false
    },
    "closeOnSelect": {
      type: Boolean,
      default: true
    },
    "multiple": {
      type: Boolean,
      default: true
    },
    "disabled": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
       
    }
  },


  watch: {
        value: function(val) {
          this.selectMode = false;
          this.$emit("input", val);
        }
  },


  mounted: function() {
     this.uid = this._uid;
     this.$store.dispatch("get_users"); 
  },

  computed: {

    ...mapFields({
        options: 'users',
    }),
  },

  methods: {
    change(event) {
      alert(event);
    },
   onSearch(search, loading) {
      //loading(true);
      this.search(loading, search, this);
    },

    search: debounce((loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/users/search/autocomplete?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        loading(false);
      });

    }, 350)
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss">
.option, .selected {
  //width: 300px;
}
</style>
