<template lang="pug">
  .filter-group(v-if="options")

    v-select(:taggable="false", :multiple="multiple", :placeholder="placeholder" v-model="project_id", :filterable="true", :reduce="it => it._id", label="name", :options="options", :id="id")
        template(slot="no-options") Type to search
        template(slot="option" slot-scope="option")

          template()
            .text-truncate
              template(v-if="!is_exclude(option)")
                  template(v-if="option.web_entity")
                      img(:src="'https://hy.ecosystem-manager.co/images/unsafe/trim/200x200/' + option.web_entity" style="width: 20px; height: 20px").mr-1
                  template(v-else)
                    avatar(:username="option.name", :size="15", :inline="true").mr-1 
                  
                    | {{ option.name }}
              
              template(v-if="is_exclude(option)").disabled.text-truncate
                  avatar(:username="option.name", :size="15", :inline="true").mr-1 
                  span.text-muted {{ option.name }}

        template(slot="selected-option" slot-scope="option")
            .selected
            
              .text-truncate(style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 200px") {{ option.name }}
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
export default {
  name: 'ProjectSelect',
  props: {
    "value":  {
      default: null
    },
    "id":  {
      default: null
    },
    "multiple":  {
      default: false
    },  
    "placeholder":  {
      default: ""
    }, 
    "create_option": {
      type: Boolean,
      default: false
    },
    "exclude_project_ids": {
      type: Array,
      default: () => {return []}
    }
  },
  data: function () {
    return {
        uid: null,
        options: null,
        project_id: null
    }
  },

  mounted: function() {
     this.project_id = this.value;
     this.uid = this._uid;
     Vue.http.get(`${Config.config.base_host}/projects`).then(res => {
        this.options = res.body;
        
     });
  },

  watch: {
        project_id: function(val) {
         
          if(this.options) {
            
            if(this.project_id && !Array.isArray(this.project_id )&& !this.options.map(x => x._id).includes(this.project_id)){
              this.project_id = null;
              
            } else if(this.project_id && Array.isArray(this.project_id )&& !this.options.map(x => x._id).filter(y => y.includes(this.project_id))) {
              this.project_id = [];
             
            }
            
           
          }

          this.$emit("input", val);
         
          
        },
        options: function() {
       
          if(this.project_id && !this.options.map(x => x._id).includes(this.project_id)){
            this.project_id = null;
          }
           
   
        }
  },
  
  methods: {
  is_exclude(option) {
    if(!this.exclude_project_ids) return false;
   
    return this.exclude_project_ids.map(x => x.project_id).indexOf(option._id) > -1;
  },
   onSearch(search, loading) {
      
      //loading(true);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/projects?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        if(vm.create_option) vm.options.unshift({name: "add", "project": {"_id": "new"}});
        
      });

    }, 350)
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}

.disabled {
      pointer-events:none !important;
      color: #bfcbd9;
      cursor: not-allowed !important;;
      background-image: none;
      background-color: #eef1f6;
      border-color: #d1dbe5;   
    }

</style>
