import Vue from 'vue'
import Config from '@/config'


import store from '../store/index'

const API_URL = Config.config.base_host + "/";
const SIGNUP_URL = API_URL + 'auth';
const USER_URL = API_URL + 'users/me';

export default {

  isInTeams: false,

  user: {
    authenticated: false
  },

  getUserData() {
    return Vue.http.get(USER_URL).then((resp) => {
        this.user = resp.data.user;
        this.user.authenticated = true;
        
        return resp.data;
    });
  },

  inTeams() {
  
      window.microsoftTeams.getContext((context) => {
         
          if (context === null || context === undefined) {
            
              this.isInTeams = true;
          } else {
            this.isInTeams = true;
     
          }
          
      });

 
    
  },
  
  openTeamChat(users, subject) {
    window.microsoftTeams.executeDeepLink("https://teams.microsoft.com/l/chat/0/0?topicName="+escape(subject)+"&users=" + users.map(x=>x.email).join(','));
  },

  openMeeting(users, subject) {
    window.microsoftTeams.executeDeepLink("https://teams.microsoft.com/l/meeting/new?subject="+escape(subject)+"&attendees=" + users.map(x=>x.email).join(','));
  },



  get_formatted(val, data_field) {
    if(!val) return "-";
    if(data_field.data_type == "percent") return val + "%";
    if(data_field.data_type == "money_amount") return val.toString().formatPrice() + "€";
    return val;
  
  },


  rewrite_custom_data(custom_data, data_fields) {
    let ret = {};
    Object.keys(custom_data).forEach( k =>  {
      let mapping = data_fields.filter(x => k == x.name);
      if(mapping.length > 0) {
        ret[mapping[0].label] = custom_data[k]
      }
    });
    return ret;
  },
  
  getUUID() {
    return Math.random()+"";
  },

  has_access(resource_group=null, resource_id=null, action=null) {

    let has_permission = false
    let user = this.user;

    if(!user.role_id) return false;

    let role = user.relations['role_id'];
    

    if(resource_id && user.permissions) {
      let permission_check = user.permissions.filter(permission => permission.resource_id == resource_id && permission.access_rights.indexOf(action) > -1);
      has_permission = permission_check.length > 0;
    } else if(user.role_id && role) {
      
      let permission_check = role.permissions.filter(permission => permission.resource_group == resource_group && permission.access_rights.indexOf(action) > -1);
      has_permission = permission_check.length > 0;
    
    }
      
    return has_permission

  },

  is_bookmarked(entry) {
    if(!this.user.following_project_ids) {
      return false;
    }

    if(this.user.following_project_ids.indexOf(entry._id) > -1) {
      return true;
    }

    return false;
  },

  add_bookmark(entry) {
    if(!this.user.following_project_ids) {
      this.user.following_project_ids = [];
    }
    this.user.following_project_ids.push(entry._id);
    this.saveUserData(this.user);
  },

  remove_bookmark(entry) {
    let index = this.user.following_project_ids.indexOf(entry._id);
    this.user.following_project_ids.splice(index,1);
    this.saveUserData(this.user);
  },

  saveUserData(user) {
    return Vue.http.put(USER_URL, user).then((resp) => {
        if(resp.body.user._id) {
          this.user = resp.body.user;
          this.user.authenticated = true;
        }
      });
  },


  changepassword(context, creds) {
    return Vue.http.post(SIGNUP_URL, creds, () => {
      this.logout();
    })
  },

  checkToken(token) {
    return Vue.http.post(API_URL + 'users/check_token', {token: token}, (data) => {
      return data
    })
  },

  newPassword(token, password) {
    return Vue.http.post(API_URL + 'v1/users/check_token', {token: token, password: password}, (data) => {
      return data
    })
  },

  signup(context, creds) {
    return Vue.http.post(SIGNUP_URL, creds, () => {
      this.logout();
    })
  },

  logout() {
    //commitlocalStorage.removeItem('id_token');
    localStorage.clear();
    this.user.authenticated = false;
    Vue.http.headers.common['Authorization'] = 'JWT none';
    location.href = "/";
  },

  checkAuth() {
  
    if(!this.user.authenticated) {
      return this.getUserData().then((resp) => {
        store.commit("LOAD_USER", resp.user);
        store.commit("LOAD_CONFIG", resp.config);
        store.commit("LOAD_API_CONFIGS", resp.api_configs);
        this.user = resp.user;
        if(resp && resp.expired) {
      
          this.logout("/web/contact?expired=1");
          location.href = "/web/contact?expired=1";
      
        } else {
          this.user.authenticated = true;
          return resp.user;
        }
        
        
        
      });
    } else {
      
      return Promise.resolve(true);
      
    }
    
  }

}