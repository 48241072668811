<template lang="pug">
  .main.shadow-sm.sticky-top
    .container-xxl
          


          template(v-if="$store.state.user && $route.name != 'bar' && (!$route.name || $route.name.indexOf('external') == -1)" )
            
              
              b-navbar(toggleable="lg").nav-bar
                  b-navbar-toggle(target="nav-collapse")
                  b-collapse(id="nav-collapse" is-nav)
                    b-navbar-nav
                      b-nav-text

                        a(to="/")
                          img(src="/hy_b.png", width="50").mr-5.logo
                          
                      b-nav-item(to="/dashboard/").mr-4 Home

                      template(v-if="instance_config.app_config.base.version == 'health'")
                        b-nav-item(to="/health/health").mr-4 Companies
                      template(v-else-if="instance_config.app_config.base.version == 'ai'")
                        b-nav-item(to="/health/health").mr-4 Companies
                      template(v-else)
                        b-nav-item(to="/ecosystem").mr-4 Companies

                      b-nav-item(to="/projects").mr-4 Projects

                       
                      b-nav-item(to="/topics", v-if="auth.has_access('section_topics', null, 'read')").mr-4 Topics
                    
                      b-nav-item(to="/news").mr-4 News
                    
                    b-navbar-nav.ml-auto
                      b-nav-form(@submit.prevent="search()", autocomplete="off")
                        b-input(type="search", placeholder="Search",size="sm", v-model="q", autocomplete="off")#quick-search-input.mr-4
                      //b-nav-item(to="/inbox").mr-5 Inbox

                      b-nav-form()
                      
                        b-nav-item-dropdown(right, id="user-dropdown-2", no-caret, v-if="notifications && notifications.filter((x) => x.notification_read_at == null).length").ml-1
                        
                          template(v-slot:button-content)
                            b-btn(variant="link", size="sm")
                              i(class="fas fa-bell")
                                b-badge(variant="info", v-if="notifications != null && notifications.filter((x) => x.notification_read_at == null).length != 0") {{ notifications.filter((x) => x.notification_read_at == null).length }} {{ notifications.filter((x) => x.notification_read_at == null).length >= 15 ? "+" : ""}} 
                          
                          .ll(style="width: 350px; overflow-y: auto; max-height: 600px").p-0.m-0
                            b-list-group().p-0.m-0
                              b-list-group-item(style="cursor: pointer; background-color: black", v-for="item in notifications", @click="read_notifications(item)").m-0
                                .d-flex.justify-content-between.align-items-center
                                  //User(:user="item.sent_by",:size="30", :popover="false").mr-3
                                  div
                                    .small.text-gray {{item.created_at | utcFrom}}
                                    .small()
                                      .truncate-2 {{item.data.text}}
                                  b-badge(variant="info", v-if="item.notification_read_at == null", pill).ml-3
                                    .text-info . 

                      b-nav-form(v-if="$store.state.instance_config && $store.state.instance_config.trial_until").small Demo exp. {{$store.state.instance_config.trial_until | utcFrom}}
                      b-nav-item-dropdown(right, id="user-dropdown-1").ml-2 
                        
                        template(v-slot:button-content)
                          span(v-if="$store.state.user")
                            a() 
                              User(:user="$store.state.user", :size="25", :popover="false")

                        //b-dropdown-item(to="/people").mr-3 Users
                        // b-dropdown-item(href="#", to="/profile") Profile
                        
                        template(v-if="$store.state.user && $store.state.user.is_admin")
                          //.dropdown-divider
                          b-dropdown-item(to="/settings/users").mr-3 Setup
                        
                        b-dropdown-item(href="#", :to="'/user_profiles/' + user.id") Profile
                        
                        //.dropdown-divider
                        b-dropdown-item(href="https://chrome.google.com/webstore/detail/hy-ecosystem-manager/jlpolaiopjnhpgokmcfoholmpoapinki", target="_blank") Chrome Extension
                        b-dropdown-item(href="https://drive.google.com/open?id=10mBRcgZPuW4ejYPsTrPYxjia0LM9S9kN" target="_blank").mr-3 Getting Started
                        //b-dropdown-item(to="/docs/help").mr-3 Help


                        .dropdown-divider
                        b-dropdown-item(href="#", @click="logout();") Logout

                
</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import User from '@/components/User'
import OnboardModal from '@/components/OnboardModal'
import { mapFields } from 'vuex-map-fields';
import auth from '@/router/auth.js'

export default {
  name: 'NavBar',
 data: function () {
    return {
      auth: auth,
      q: null,
      lastRoute: null,
      notifications: null
    }
  },


  watch:{
      q (val) {
       
        if(val) {
          this.$router.replace({name: "search", query: { "q": val, "lastRoute": this.lastRoute}});
        } else {
          
          this.$router.replace({path: this.lastRoute});
        }
        
     },

      $route (to, from){
    
        if(from.name != "search") {
          this.lastRoute = from.path;
          
        }
        
        if(from.name == "search" && to.name != "search" && this.$route.query.source != "qs") {
          this.q = "";
        }
      }
  },

 computed: {

        ...mapFields([
            'instance_config',
            'user'
        ]),
  },

  beforeMount() {
    window.onresize = () => {
      this.$eventHub.$emit('resize');
    }
  },


  mounted: function() {
     
     this.host = Config.config.base_host;
     
      if(this.$store.state.user && this.docId) {
        this.getData();
      }

      

  },

  props: {
    activeRoute: String
  },

  components: {
    User,
    OnboardModal
  },
  
  methods:  {
    search() {
      this.$router.replace({name: "search", query: { "q": this.q }});
      this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
    },

     read_notifications(notification) {
    
      this.$api.read_notifications(notification).then(()=>{
         this.$api.get_my_notifications().then(res => {
          this.notifications = res;
        });
      });
    
    },


    logout() {
        //localStorage.removeItem('id_token');
        //localStorage.clear();
        Vue.http.get(`${Config.config.base_host}/users/logout`).then(() => {
          document.location.reload();
        });
        Vue.http.headers.common['Authorization'] = 'JWT none';
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';

    .logo {
      width: 30px;
      
      margin-top: -2px;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }

    .main {
      background-color: $navbar-color;
      z-index: 1021;
      border: 0;
      
    }

    .disabled {
      display:none;
    }

    .feed-image-small {
      width: 28px;
      height: 28px;
    }
    .navbar {
      padding-top: 0;
      padding-bottom: 0;
    }
    .nav-bar { 
        padding: $pad/2 2*$pad 0 2*$pad;
        //height: 50px;
        
        z-index: 1030 !important;
        a {
            &.nav-link {
              padding: $pad/2 $pad 0 $pad;
            } 
            text-transform: $text-transform-menu; //uppercase;
            font-size: $font-size-mainmenu;
            color: $gray-default;
            
            font-weight: 600;
            //letter-spacing: 0.5px;
            
            height: 50px;
            border-bottom: 4px solid transparent;
            &:hover {
              text-decoration:none;
            }
            &.router-link-active {
                transition: border 0.4s ease-in-out;
                font-weight: bold;
                border-bottom: 4px solid $primary;
                color: $primary;
            }
        }

    }

</style>