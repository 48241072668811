import Vue from 'vue'
import Config from '@/config'

const API_URL = Config.config.base_host + "/";


export default {
  
  search_comments(search) {
  
    return Vue.http.post(API_URL + 'comments/search', search).then((resp) => {
      return resp.data;
    });
  
  },

  save_project(project, callback ) {
  
    return Vue.http.put(API_URL + 'projects/'+project._id, project).then((resp) => {
      if( callback && typeof(callback) == "function" ){
        callback( resp.data );
      }

      return resp.data;
    });
  
  },

 
  add_comment(comment) {
  
    return Vue.http.post(API_URL + 'comments', comment).then((resp) => {
     
      return resp.data;
    });
  
  },

  send_notification(notification) {
  
    return Vue.http.post(API_URL + 'notifications/general', notification).then((resp) => {
     
      return resp.data;
    });
  
  },

  

  get_my_notifications() {
  
    return Vue.http.get(API_URL + 'users/me/notifications').then((resp) => {
      return resp.data;
    });
  
  },


  get_user(user_id) {
  
    return Vue.http.get(API_URL + `users/lookup/${user_id}`).then((resp) => {
      return resp.data;
    });
  
  },


  upload_image(image_payload, image_type = "logo") {
    return Vue.http.post(API_URL + 'images', {"image_payload": image_payload, "image_type": image_type}).then((resp) => {
      return resp.data;
    });
  },


  read_notifications(notification) {
    notification.notification_read_at = true;
    return Vue.http.put(API_URL + 'notifications/' + notification._id, notification).then((resp) => {
      return resp.data;
    });
  },

  get_usecase_assessments(topic_id) {

    return Vue.http.post(`${Config.config.base_host}/surveys/search`, {"survey_template_id": ["SurveyTemplate:use_case_assessment"], "topic_id": [topic_id]}).then(res => {
        return res.body;
    });

  },


  get_surveys(topic_id) {

    return Vue.http.post(`${Config.config.base_host}/surveys/search`, {"survey_template_id": ["SurveyTemplate:startup_assessment"], "topic_id": [topic_id], "enrich": ['organization_id']}).then(res => {
        return res.body;
    });

  },

  search_organizations() {


  }

}