<template lang="pug">
  .user-modal
    b-modal(id="role-modal", :no-close-on-backdrop="true", :title="title", size="md", ok-variant="primary", cancel-variant="link", ref="wlistref",  @ok="save()")
      b-form(size="sm", autocomplete="off", v-if="$store.state.user && $store.state.user.id")
        .form-row()
         
          .col-12
            
            b-form-group(id="name-group"  
                        label="Sender"
                        size="sm"
                       
                        label-for="name"
                        description="",
                       )
            
              User(:user="$store.state.user", :show-name="true")
             
             

        .form-row()
         
          .col-12

            b-form-group(id="name-group"  
                        label="Your feedback"
                        size="sm"
                       
                        label-for="name"
                        description="",
                        v-bind:class="{ 'form-group--error': $v.form.name.$error }")
              
              b-textarea(v-model="feedback", placeholder="Your questions or suggetions for improvement", :rows="7")
                


      template(slot="modal-footer")
            b-btn(variant="secondary" @click="$refs.wlistref.hide(false)") Close
            b-btn(variant="primary"  @click="save()", :disabled="!feedback")#user-modal-save Send feedback



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import User from '@/components/User'
import UseCaseSelect from '@/components/UseCaseSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import Config from "@/config";
import Vue from 'vue';

export default {

  name: 'WFeedbackModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
    }
  },

  data: function () {
    return {
        project_id: null,
        feedback: null,
        topic_id: null,
        name_chosen: false,
        uid: null,
        wlists: null,
        title: "Save list",
        form: {"name": "", "items": [], "active": true, "send_confirmation": true},
        wlist: {},
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Role"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {

    open(project) {
      
      this.title = "Thank you for the feedback";
      this.name_chosen = "";
      this.wlists = null;
      
      console.log(project);

     

      if(project) {
        this.form = JSON.parse(JSON.stringify(project));
        this.wlist = project;
        this.ok_btn_text = "Add";
        this.title = "Your feedback";
        
      } else {
        
        this.form = {"name": "", "permissions": [], "active": true, "send_confirmation": true};
        this.wlist =  {"name": "", "permissions": [], "active": true, "send_confirmation": true};
      
      }

     
      this.$refs['wlistref'].show()
    },


    
    
    save() {
     
      Vue.http.post(`${Config.config.base_host}/feedback_ticket` ,{url: location.href, 'link_text': location.href, 'description': this.feedback, 'subject': "Feedback"}).then(() => { 
         this.$bvToast.toast("Thank you for your feedback.", {
              title: 'Thank you!',
              variant: 'primary',
              autoHideDelay: 10000,
         });
         this.feedback = "";
         this.$refs['wlistref'].hide();

      });

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect,
    ProjectStageSelect,
    UseCaseSelect,
    User
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
