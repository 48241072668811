import Vue from 'vue'
import VueResource from 'vue-resource';

import App from './App.vue'
import router from './router/index.js'
import store from './store'

import vSelect from 'vue-select'

import hySelect from '@/components/hyselect'

import VueMoment from 'vue-moment';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueUploadComponent from 'vue-upload-component'
import VueCurrencyInput from 'vue-currency-input'

import Affix from 'vue-affix';
import Config from '@/config';
import infiniteScroll from 'vue-infinite-scroll'
import VueVirtualScroller from 'vue-virtual-scroller'
import Trend from "vuetrend"

import auth from '@/router/auth.js'
import api from '@/router/api.js'

import _ from 'lodash';    

import SetImageAsSrc from '@/directives/SetImageAsSrc';
import SetImageAsBG from '@/directives/SetImageAsBG';
import HCard from '@/components/HCard';
import HPin from '@/components/HPin';
import HPinUser from '@/components/HPinUser';
import VueSync from 'vue-sync';
import VueTour from 'vue-tour'
import HEditor from '@/components/HEditor'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

Object.defineProperty(Vue.prototype, '$_', { value: _ });
Object.defineProperty(Vue.prototype, '$auth', { value: auth });
Object.defineProperty(Vue.prototype, '$api', { value: api });

Vue.use(infiniteScroll)
Vue.use(Affix);
Vue.use(VueSync)


Vue.directive('set-image-src', SetImageAsSrc);
Vue.directive('set-image-bg', SetImageAsBG);

Vue.component('h-card', HCard)
Vue.component('h-pin', HPin)
Vue.component('h-pinuser', HPinUser)
Vue.component('h-editor', HEditor)


// register directive v-money and component <money>
Vue.use(VueCurrencyInput)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueResource);
Vue.use(VueMoment);

Vue.use(Vuelidate)
Vue.use(VueVirtualScroller)

Vue.use(Trend)


vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('i', {
      attrs: { class: ["fas", "fa-times"].join(" ")}
    },''),
  },
  OpenIndicator: {
    render: createElement => createElement('i', { class: ["fas", "fa-caret-down"].join(" ")
    },''),
  },
});


Vue.component('v-select', vSelect)
Vue.component('hy-select', hySelect)
Vue.component('file-upload', VueUploadComponent)

Vue.config.productionTip = false;

Vue.http.options.credentials = true;

// Intercept all requests
Vue.http.interceptors.push( function(request ) {
  
  //request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('id_token'));

  if( window.location.pathname != "/" && window.location.pathname.indexOf("/users/") == -1 && window.location.pathname.indexOf("/web/") == -1  ){
    // return response callback
    return function(response) {
      if( response.status == "401" && request.url.indexOf("/users/refresh_login") != -1  ){
        //localStorage.removeItem('id_token');
        //localStorage.clear();
        Vue.http.get(`${Config.config.base_host}/users/logout`).then(res => {
          console.log( "Logout res", res );
          setTimeout(() => {
            if( window.location.pathname != "/" && window.location.pathname.indexOf("/users/") == -1 && window.location.pathname.indexOf("/web/") == -1  ){
              document.location.reload();
            }
          }, 500);
        }).catch( ex => {
          console.log( "Logout ex", ex );
          setTimeout(() => {
            if( window.location.pathname != "/" && window.location.pathname.indexOf("/users/") == -1 && window.location.pathname.indexOf("/web/") == -1  ){
              document.location.reload();
            }
          }, 500);
        });
        Vue.http.headers.common['Authorization'] = 'JWT none';
      }
      else if( response.status == "401" ){
        if(response.body.detail.indexOf("token_expired") > -1 && request.url.indexOf("/me") == -1 ) {
        
          return Vue.http.get(`${Config.config.base_host}/users/refresh_login`).then(res => {
            console.log(" refresh response", res )
            var request_method = request.method.toLowerCase();
            console.log(request.url, request);
            if(request_method == 'get' || request_method == 'delete') {
              return Vue.http[ request_method ](request.url, request.params);
            } else {
              try {
                return Vue.http[ request_method ](request.url, JSON.parse(request.body), request.params);
              } catch {
                return Vue.http[ request_method ](request.url, request.body, request.params);
              }
            }
            
          });
        }
      }
      
    };
  }

});



String.prototype.formatPrice = function() {
  let value = this;
  if(!value) return "";
  
  let m = false;

  if((value/1) > 100000) {
      m = true;
      value = (value/1000000);
  }
  let val = (value/1).toFixed(2).replace('.', ',')

  if(m) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "M"
  }
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

}

String.prototype.formatPriceMillion = function() {
  let value = this;
  if(!value) return "";
  
  value = (value/1000000);

  let val = (value/1).toFixed(2).replace('.', ',')
 
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "M"

}

Vue.prototype.$eventHub = new Vue();

window.addEventListener('message', function (e) {
  if (e.data.event === 'iframe_saved') {
    Vue.prototype.$eventHub.$emit('iframe_saved', e.data.data);

  }
});

Vue.filter('utcFrom', function (value) {
	return Vue.prototype.$moment.utc(value).from();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.init_twitter=function(){ window.twttr=function(e,t,i){var a,n=e.getElementsByTagName(t)[0],s=window.twttr||{};return e.getElementById(i)?s:((a=e.createElement(t)).id=i,a.src="https://platform.twitter.com/widgets.js",n.parentNode.insertBefore(a,n),s._e=[],s.ready=function(e){s._e.push(e)},s)}(document,"script","twitter-wjs")}
//window.microsoftTeams.appInitialization.notifySuccess()