<template lang="pug">
  #app.min-h-100.app-page
    WFeedbackModal(ref="g_feedback")
    NavBar(v-if="!hasUrlRedirect && !hasExtension")
    router-view(name="submenu")
    
    b-btn( variant="link", @click="$emit('openFeedbackModal')", title="Feedback" v-b-tooltip.hover, v-if="!hasExtension && $route.path.indexOf('/users/') == -1").feedback.text-gray.shadow-sm
      i.fas.fa-comment-alt
    
</template>

<script>

// @ is an alias to /src
import NavBar from '@/components/layout/NavBar.vue'
import WFeedbackModal from '@/components/WFeedbackModal.vue'


export default {
  name: 'home',

  data: function() {
    return  {
      hasUrlRedirect: false,
      extension: false
    }  
  },

  created() {
    this.hasUrlRedirect = location.href.indexOf("redirect") > -1;
    this.hasExtension = location.href.indexOf("extension") > -1 || location.href.indexOf("wiki/w") > -1 || location.href.indexOf("/web") > -1;
    this.$on('openFeedbackModal', (x) => this.$refs.g_feedback.open(x));

  },

 
  beforeDestroy() {
    this.$off('openFeedbackModal');
  },

  components: {
    NavBar,
    WFeedbackModal
  }
}
</script>

<style lang="scss">

@import './custom.scss';
.container-fluid {
  padding: 0;
 
}
.router-view-wrap {
  padding: $pad;
}
 
.mc {
    width: 100%;
    height: 100%;
    background-color:$primary;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    color: $primary;
}

.feedback {
  text-align: center;
  color: white;
  &:hover {
    color: grey;
  }
  background-color: $black;
  position: fixed;
  width: 40px; height: 40px;
  bottom: 10px;
  right: 5px;
  z-index: 20;
  border-radius: 50%;
 
}

#mapid { height: 500px; width: 100%; }

</style>
