// import Vue from "vue";

// function fetchImage( imageUrl, callback ){
//   let headers = {
//     'responseType': 'arraybuffer',
//   };
//   if( !imageUrl ){
//     return false;
//   }
//   Vue.http.get(imageUrl, headers ).then(res => {
//     var mimeType    = res.headers.map['content-type'][0].toLowerCase(),
//         imgBase64   = new Buffer(res.data, 'binary').toString('base64'),
//         imageData   = 'data:' + mimeType + ';base64,' + imgBase64;
//     console.log("Res BG", res );
//     callback( imageData );
//   }).catch((function( ex, data ) {
//     console.error("Exception ", ex );
//     console.error("Exception Data", data );
//     callback( imageUrl );
//   }));
// }

function setImgAsBG(el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    el.style.backgroundImage = "url('" + binding.value + "')"; 
    // fetchImage( binding.value, ( imageData )=>{ 
    //   el.style.backgroundImage = "url('" + imageData + "')"; 
    // })
  }
}


export default {
  bind: function(el, binding) {
    setImgAsBG(el, binding);
  },
  componentUpdated: function(el, binding) {
    setImgAsBG(el, binding);
  }
};