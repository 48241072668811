<template lang="pug">
    
        b-card(no-body, v-bind:class="variantBody").h-card
            .header(v-bind:class="variant")
                .pl-3.pt-3.pr-3.pb-3.mb-0.d-flex.flex-columns.justify-content-between()
                    .d-inline-flex.align-items-center
                        span(v-bind:class="icon" v-if="icon", style="font-size: 1.5em").mr-2
                        h5().p-0.m-0.text-break {{title}} 
                        slot(name="header-add")
                    div
                       
                        slot(name="header-left")       

            div(v-bind:class="{'p-3': !noBody, 'p-0': noBody}")
                slot()
             
                .short(v-if="!showMore || shortAddMode")
                    slot(name="short")
                    
                    template(v-if="showMore && shortAddMode") 
                        slot(name="long")

                .long(v-if="showMore && !shortAddMode")
                    slot(name="long")
          
            .d-flex.justify-content-center(v-if="showMoreDisplay")
                .p-0(v-show="!showMore")
                    b-btn(@click="showMore=true", variant="link").form-control
                        i.fas.fa-angle-down

                .p-0(v-show="showMore")
                    b-btn(@click="showMore=false", variant="link").form-control
                        i.fas.fa-angle-up


</template>

<script>
// @ is an alias to /src

export default {
  
  name: 'HCard',
  props: ["title", "showMoreDisplay", "icon", "variant", "shortAddMode", "showLong", "noBody", "variantBody"],
  data: function () {
    return {
      uid: null,
      showMore: false,
      noBodyIntern: false
    }
  },

  mounted: function() {
      if(this.showLong) {
          this.showMore = true;
      }
     this.uid = this._uid;
  },

     
  
  methods: {
      update() {
        this.$emit("update");
      }
    

  },

  components: {
    
  }
}

</script>
<style lang="scss" scoped>

@import '@/_vars.scss';
    .long {
        max-height: 800px;
        overflow-y: auto;
        overflow-x: hidden;
       
    }

    .cluster {
        background-color:$hyg4;
    }

    .info {
        background-color:  $gray-600;
    }

    .dark-gray {
        background-color:  $gray-900;
    }
    
    .green {
        background-color:  #2F4F2A
    }

    hr {
       border-color: #4B4B4A !important;
    }

    .short, .long {
        .list-group-item {
            border-color: #4B4B4A !important;
            padding: 10px 0  !important;
        }
    }

    .list-group-item {

        border-color: #4B4B4A !important;
        padding: 10px 0  !important;
    }

    .list-group-item-action:focus,.list-group-item-action.active {
        border: 2px solid $primary !important;
        
        background-color: transparent !important;    
    }


</style>
